.cars {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin-top: 2rem;
}

.cars-title {
  font-size: 3rem;
  margin: 1rem;
  color: #ccc;
}

.cars-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 1rem;
  border-radius: 10px;
  height: auto;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  margin: 0 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #eaeaea;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation-buttons button:hover {
  background-color: #ddd;
}

.navigation-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.categories-buttons button {
  background-color: #f5f5f5;
  color: #888;
  padding: 6px 10px;
  font-size: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.categories-buttons button.active {
  background-color: #ccc;
  color: #fff;
}

@media screen and (max-width: 955px) {
  .cars-box {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
