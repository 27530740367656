@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

body {
  background-color: rgb(86, 75, 96);
  margin: 0;
  padding: 0;

}

@media screen and (max-width: 992px) {
  body {
    overflow-x: hidden;
  }

}