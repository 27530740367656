.cars-inner-box {
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    height: auto;
    width: 33%;
  }
  
  .cars-img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
  }
  
  
  .cars-inner-box h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .cars-inner-box p {
    font-size: 0.8rem;
    line-height: 1.5;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

    
  @media screen and (max-width: 955px) {
    .cars-inner-box {
      width: 80%;
      height: auto;
      display: none;
    }

    .cars-inner-box:first-child {
      display: block;
    }
  }
  