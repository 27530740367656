.container {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: rgb(86, 75, 96);
}

.box {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50%;
    display: flex;
}

.infos {
    width: 50%;
    margin: 1rem 0.5rem;
}

.infos img {
    width: 100%;
    height: 100%;
    padding: 1rem;
    object-fit: cover;
}

.box:last-child {
    background-color:rgb(57, 56, 63);
}

@media screen and (max-width: 992px) {
    .infos {
        width: 100%;
        height: auto;
    }
    .box {
      flex-direction: column;
    }
    .infos img {
        padding: 0;
        width: 100%;
    }

    .container {
        height: auto;
    }

  }